<template>
  <div>
    <navbar></navbar>
    <router-view />
    <footer-customer></footer-customer>
  </div>
</template>
<script>
import navbar from "../components/customers/NavbarCustomer.vue";
import footerCustomer from "../components/customers/FooterCustomer.vue";

export default {
  components: {
    navbar,
    footerCustomer,
  },

  data() {
    return {};
  },
};
</script>
